import * as React from "react"

function IconError(props) {
    return (
        <svg
            width={32}
            height={32}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.761 5.82a1.538 1.538 0 00-.248.287L2.926 21.464C.953 24 2.32 27 5.258 27h21.39c2.939 0 4.306-3 2.333-5.535L18.394 6.107a1.539 1.539 0 00-.248-.287 3.341 3.341 0 00-4.385 0z"
                fill="#FF0F18"
            />
            <path
                d="M15.954 18v-7M15.954 23v-1"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconError
