import App from './App';
import Competences from './Competences';
import Entrance from './Entrance';
import Finish from './Finish';
import MovingMotivators from './MovingMotivators';
import PersonalMap from './PersonalMap';
import VideoUpload from './VideoUpload';

export {
    App,
    Competences,
    Entrance,
    Finish,
    MovingMotivators,
    PersonalMap,
    VideoUpload
};
