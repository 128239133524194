import * as React from "react"

function IllustrationEvidenceMatch(props) {
    return (
        <svg
            width={277}
            height={273}
            viewBox="0 0 277 273"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M59.787 204.462l16.324-44.32a5.78 5.78 0 017.09-3.542l55.414 16.613a5.785 5.785 0 013.925 7.043l-10.336 38.405s-1.438-1.25-3.696.834c-2.251 2.083-20.147 20.604-42.29 11.007-22.143-9.603-25.41-21.686-26.43-26.04z"
                fill="#4BD6C5"
            />
            <path
                d="M154.112 127.878c-12.238 0-23.057-2.782-29.65-4.475-1.209-.31-2.271-.585-3.172-.793a1.007 1.007 0 01.457-1.963c.907.215 1.983.491 3.212.807 17.775 4.569 64.998 16.706 87.759-37.371 11.492-29.851 8.663-42.25 7.305-48.204-.638-2.796-1.102-4.818 1.129-6.17a2.876 2.876 0 012.278-.335c2.372.652 4.268 4.193 6.102 7.627.343.639.672 1.257.981 1.815 2.413-.572 9.483-1.418 14.718 6.525a1.004 1.004 0 01-.289 1.398 1.004 1.004 0 01-1.398-.29c-5.464-8.285-12.849-5.63-13.158-5.51a1.01 1.01 0 01-1.223-.43 69.265 69.265 0 01-1.418-2.56c-1.398-2.621-3.32-6.21-4.852-6.633-.249-.067-.457-.034-.699.114-.874.531-.901.981-.215 3.999 1.404 6.155 4.327 18.957-7.399 49.406-10.027 23.817-25.94 37.902-47.317 41.874-4.469.833-8.898 1.169-13.151 1.169z"
                fill="#3A3A3A"
            />
            <path
                d="M166.9 167.897c-7.735 0-12.782-.995-12.903-1.021a1.015 1.015 0 01-.786-1.19c.114-.544.638-.9 1.189-.786.302.06 30.577 6.008 56.826-12.043 19.213-13.212 31.565-35.738 36.712-66.94 8.172-49.568 7.89-67.8 7.89-67.974.027-1.028-.249-3.192-1.237-3.286-.551-.054-.833.127-.988.269-.436.376-.719 1.196-.772 2.19l-.666 8.4c-.04.558-.537.968-1.082.928a1.013 1.013 0 01-.927-1.082l.658-8.367c.041-.887.289-2.567 1.459-3.588.665-.578 1.525-.84 2.493-.753 2.547.229 3.125 3.44 3.071 5.302.007.128.302 18.48-7.916 68.298-5.242 31.779-17.883 54.756-37.58 68.29-16.175 11.108-33.836 13.353-45.441 13.353zM230.829 33.97a1.008 1.008 0 01-.995-1.183l2.473-14.098c.31-1.714 1.452-4.556 3.831-4.375 2.076.161 3.273 1.868 3.205 4.563-.026 1.15-.887 13.615-.927 14.146a1.007 1.007 0 11-2.009-.141c.309-4.476.9-13.178.92-14.059.027-1.088-.181-2.412-1.344-2.5h-.04c-.806 0-1.472 1.754-1.653 2.722l-2.466 14.085c-.088.498-.511.84-.995.84zM129.448 90.642c-4.623 0-7.721-3.528-8.823-5.719a1.014 1.014 0 01.665-1.438l16.028-3.824a.997.997 0 01.833.175c.235.181.39.457.403.753.148 2.68-1.008 8.158-6.458 9.677a9.977 9.977 0 01-2.648.376zm-6.317-5.524c1.223 1.653 4.019 4.429 8.434 3.199 3.831-1.062 4.731-4.51 4.933-6.391l-13.367 3.192z"
                fill="#3A3A3A"
            />
            <path
                d="M115.194 69.083a5.806 5.806 0 100-11.611 5.806 5.806 0 000 11.611zM150.556 66.51a5.807 5.807 0 100-11.614 5.807 5.807 0 000 11.614z"
                fill="#4CD6C5"
            />
            <path
                d="M108.723 51.295a.992.992 0 01-.826-.43 1.011 1.011 0 01.248-1.405c.256-.181 6.405-4.388 12.923-1.552.511.222.746.813.524 1.324-.221.51-.813.746-1.324.524-5.477-2.379-10.913 1.317-10.967 1.358a1.013 1.013 0 01-.578.181zM142.251 48.95c-.316 0-.632-.148-.827-.43a1.012 1.012 0 01.249-1.405c.255-.181 6.404-4.388 12.922-1.552a1.007 1.007 0 11-.799 1.848c-5.497-2.392-10.914 1.317-10.968 1.357a.963.963 0 01-.577.182zM129.67 70.091a1.01 1.01 0 01-.981-.78 1.01 1.01 0 01.753-1.209c1.102-.255 1.868-.752 2.271-1.472.733-1.303.175-3.017.168-3.03a.908.908 0 01-.04-.182l-2.137-15.335a1.008 1.008 0 011.996-.276l2.13 15.248c.148.504.699 2.668-.342 4.543-.693 1.243-1.902 2.077-3.582 2.466a.978.978 0 01-.236.027zM110.604 124.438c-8.723 0-15.49-3.004-15.886-3.186a1.003 1.003 0 01-.592-.92V83.088c-2.54-.497-9.126-2.856-9.616-13.964-.027-.578-.054-1.19-.088-1.835-.362-7.345-.86-17.398 2.641-21.175.908-.974 2.023-1.492 3.32-1.525 1.378-.034 2.56.456 3.569 1.471 3.79 3.817 3.487 14.234 3.474 14.67a1.007 1.007 0 01-1.042.975 1.007 1.007 0 01-.974-1.042c.094-2.768-.249-10.53-2.89-13.185-.605-.611-1.297-.894-2.083-.873-.746.02-1.364.309-1.895.88-2.923 3.152-2.413 13.514-2.11 19.704.033.651.06 1.263.087 1.84.524 11.775 8.306 12.158 8.642 12.171.545.02.981.464.981 1.008v37.445c2.615 1.028 13.468 4.791 24.368 1.243v-11.612c0-.558.45-1.008 1.008-1.008s1.008.45 1.008 1.008v12.338c0 .43-.269.806-.672.948-3.871 1.377-7.722 1.868-11.25 1.868z"
                fill="#3A3A3A"
            />
            <path
                d="M123.964 104.008c-1.035 0-2.029-.04-2.977-.114-9.657-.739-16.653-4.69-16.942-4.859a1.012 1.012 0 01-.376-1.377 1.012 1.012 0 011.378-.377c.067.04 6.948 3.911 16.168 4.604 12.285.92 22.688-3.945 30.954-14.449 19.824-25.207-1.17-57.511-1.378-57.834a1.008 1.008 0 011.68-1.115c.229.343 5.591 8.514 8.494 20.04 3.871 15.355 1.378 29.239-7.21 40.152-9.718 12.359-21.095 15.329-29.791 15.329zM1.013 166.896a1.005 1.005 0 01-.39-1.936c24.186-10.053 39.77-22.546 49.05-39.306 8.596-15.524 11.337-33.292 14.025-54.91 3.179-25.578 11.021-44.569 23.3-56.437C99.462 2.251 113.42.457 122.936 1.068c20.066 1.297 22.546 13.32 22.828 16.976.403 5.248-1.922 10.113-5.188 10.84a1.008 1.008 0 11-.437-1.97c1.996-.443 3.965-4.133 3.616-8.716-.995-12.842-16.29-14.818-20.954-15.12-9.105-.592-22.472 1.135-34.407 12.674-11.935 11.545-19.576 30.127-22.7 55.233-2.716 21.834-5.491 39.797-14.26 55.637-9.503 17.183-25.41 29.958-50.033 40.2a1.145 1.145 0 01-.39.074z"
                fill="#3A3A3A"
            />
            <path
                d="M89.316 46.806c-.048 0-.101 0-.148-.013a1.008 1.008 0 01-.854-1.143c.47-3.178 2.043-5.766 4.684-7.681 6.277-4.55 18.521-5.296 36.383-2.224 7.601 1.31 13.434.37 17.338-2.796 4.644-3.756 4.926-9.482 4.926-9.543a1 1 0 011.049-.967c.557.02.987.483.967 1.041-.007.27-.295 6.667-5.651 11.015-4.382 3.561-10.766 4.65-18.972 3.232-17.069-2.937-29.125-2.285-34.864 1.868-2.218 1.606-3.48 3.683-3.87 6.344a.998.998 0 01-.989.867zM15.508 253.398a1.012 1.012 0 01-.437-1.922c19.246-9.106 35.375-21.693 47.942-37.418 10.053-12.587 17.868-27.197 23.224-43.433 9.126-27.687 7.91-50.804 7.897-51.033a.999.999 0 01.947-1.062.987.987 0 011.062.948c.013.235 1.263 23.668-7.97 51.712-5.423 16.478-13.346 31.316-23.554 44.098-12.762 15.98-29.146 28.775-48.681 38.022a1.224 1.224 0 01-.43.088zM230.667 180.47c-14.966 0-28.507-4.092-40.301-12.19-10.309-7.076-19.294-17.204-26.719-30.093-12.601-21.888-16.377-44.541-16.411-44.77a1.006 1.006 0 01.833-1.156 1.009 1.009 0 011.156.834c.034.221 3.763 22.559 16.196 44.131 7.271 12.621 16.054 22.513 26.108 29.408 12.533 8.595 27.102 12.539 43.331 11.706a1.002 1.002 0 011.055.954 1.001 1.001 0 01-.954 1.055 76.702 76.702 0 01-4.294.121zM155.24 267.598c-.027 0-.054 0-.088-.007a1.005 1.005 0 01-.92-1.089c.006-.047.403-5.1 1.122-31.067.376-13.575 3.783-20.087 7.076-26.384 3.118-5.96 6.069-11.599 6.069-23.097 0-16.847-11.23-34.817-11.344-34.992a1.01 1.01 0 011.707-1.075c.477.753 11.653 18.635 11.653 36.074 0 11.995-3.058 17.842-6.297 24.031-3.192 6.095-6.485 12.392-6.848 25.503-.719 26.095-1.116 30.98-1.129 31.182-.04.524-.484.921-1.001.921z"
                fill="#3A3A3A"
            />
            <path
                d="M108.649 272.51c-17.634 0-41.329-3.85-59.601-15.893-18.145-11.955-27.627-29.455-28.198-52.008-.914-36.524 15.86-56.933 30.093-67.618 15.403-11.565 30.785-14.448 31.43-14.563a1.01 1.01 0 011.17.814c.1.544-.263 1.075-.814 1.169-.154.027-15.617 2.937-30.644 14.247-13.823 10.402-30.113 30.281-29.219 65.904.544 21.861 9.73 38.809 27.29 50.375 27.856 18.353 68.426 17.338 79.601 12.862.141-.047 9.798-3.501 11.908-8.958.646-1.666.498-3.326-.443-5.067a1.005 1.005 0 01.403-1.364 1 1 0 011.364.403c1.223 2.258 1.411 4.53.551 6.754-2.439 6.324-12.647 9.959-13.077 10.114-4.079 1.64-12.009 2.829-21.814 2.829z"
                fill="#3A3A3A"
            />
            <path
                d="M127.237 248.996c-.397 0-.773-.235-.934-.632a1.004 1.004 0 01.558-1.31c.141-.061 14.435-5.941 15.12-13.548.296-3.28-2.076-6.532-7.043-9.684a1.004 1.004 0 01-.309-1.391c.296-.47.921-.612 1.391-.309 5.659 3.588 8.34 7.479 7.97 11.572-.799 8.83-15.732 14.973-16.37 15.235a1.097 1.097 0 01-.383.067z"
                fill="#3A3A3A"
            />
            <path
                d="M130.698 255.427a1 1 0 01-.934-.638 1.009 1.009 0 01.565-1.311c.101-.04 10.322-4.166 12.331-10.389.679-2.097.343-4.153-1.021-6.297a1.003 1.003 0 01.309-1.391 1.01 1.01 0 011.391.309c1.687 2.641 2.104 5.336 1.243 8.004-2.305 7.123-13.044 11.458-13.501 11.639a1.01 1.01 0 01-.383.074z"
                fill="#3A3A3A"
            />
            <path
                d="M133.797 260.568a1.008 1.008 0 01-.33-1.962c.471-.161 11.559-4.133 8.656-13.407a1.007 1.007 0 111.922-.605c3.481 11.149-9.791 15.867-9.926 15.914a.917.917 0 01-.322.06zM129.166 235.166a1.006 1.006 0 01-.927-1.405c3.79-8.729 4.98-13.507 3.535-14.186-1.284-.598-2.265.215-4.987 2.89-.772.752-1.639 1.613-2.654 2.527-1.821 1.653-18.521 15.826-42.982 4.549-12.916-5.954-18.716-15.14-21.31-21.793-2.81-7.225-2.56-12.977-2.547-13.219a1.007 1.007 0 012.01.101c-.014.222-.794 22.264 22.687 33.083 9.959 4.59 19.69 5.397 28.91 2.393 6.633-2.158 10.766-5.605 11.868-6.606.981-.887 1.841-1.734 2.594-2.473 2.674-2.628 4.603-4.523 7.258-3.28 3.655 1.714.88 8.931-2.541 16.814a.993.993 0 01-.914.605z"
                fill="#3A3A3A"
            />
            <path
                d="M125.389 238.721c-.518 0-.961-.396-1.002-.927a1.005 1.005 0 01.921-1.089c.074-.007 7.695-.726 8.373-6.216a1.011 1.011 0 011.123-.88 1 1 0 01.873 1.122c-.369 2.984-2.284 5.315-5.53 6.74-2.352 1.028-4.577 1.223-4.671 1.237-.033.013-.06.013-.087.013zM237.442 33.97a1.008 1.008 0 01-.995-1.183l2.473-14.098c.329-1.942 1.593-5.141 4.18-4.778 3.4.477 3.017 4.267 2.856 5.887a1.008 1.008 0 01-1.102.9 1.01 1.01 0 01-.901-1.102c.35-3.481-.504-3.602-1.129-3.69-1.041-.14-1.767 2.238-1.915 3.126l-2.473 14.105c-.087.49-.517.834-.994.834z"
                fill="#3A3A3A"
            />
            <path
                d="M240.929 33.03c-.121 0-.249-.007-.37-.02-1.982-.195-3.124-2.09-3.044-5.074.027-1.176 1.022-7.036 1.136-7.701a1.015 1.015 0 011.163-.827c.551.094.92.611.826 1.162-.43 2.554-1.089 6.633-1.102 7.413-.013.484-.013 2.896 1.223 3.017 1.794.175 2.104-2.648 2.117-2.768l.847-6.418c.033-.645.302-4.597 2.19-6.23a2.944 2.944 0 012.258-.726c.552.054.955.551.901 1.102-.054.552-.551.961-1.102.901-.282-.027-.504.047-.733.242-.974.84-1.438 3.414-1.498 4.838 0 .027-.007.06-.007.088l-.847 6.424c-.02.222-.282 2.547-1.794 3.804a3.362 3.362 0 01-2.164.773z"
                fill="#3A3A3A"
            />
            <path
                d="M273.77 32.922l-23.951-6.753c.014 1.37-.114 5.046-1.653 6.216l23.857 6.727a3.213 3.213 0 003.965-2.224 3.212 3.212 0 00-2.218-3.966zM224.445 19.011a3.208 3.208 0 00-3.965 2.225 3.21 3.21 0 002.225 3.965l8.541 2.405.981-6.404-7.782-2.19z"
                fill="#4CD6C5"
            />
            <path
                d="M158.477 1.8l-.912 3.234L223.12 23.52l.912-3.234L158.477 1.8z"
                fill="#412ECC"
            />
            <path
                d="M222.711 24.865a3.199 3.199 0 100-6.398 3.199 3.199 0 000 6.398zM157.237 6.398A3.199 3.199 0 10157.236 0a3.199 3.199 0 00.001 6.397z"
                fill="#E31C46"
            />
            <path
                d="M234.686 31.477c-.128 0-.262-.006-.397-.02-2.043-.201-3.219-1.976-3.152-4.744.027-1.176.8-5.934.888-6.472.087-.551.604-.92 1.155-.833.551.087.921.605.834 1.156-.336 2.063-.84 5.41-.86 6.196-.027.954.114 2.567 1.33 2.688.45.047.807-.047 1.096-.296.658-.551.88-1.747.913-2.117l.733-6.203a1.008 1.008 0 112.003.242l-.726 6.156c-.014.188-.209 2.278-1.62 3.468-.605.517-1.357.78-2.197.78zM246.56 33.682c-.127 0-.262-.007-.396-.02-2.043-.202-3.219-1.976-3.152-4.745.027-1.17.672-6.882.699-7.123.06-.552.558-.955 1.115-.887.552.06.955.557.888 1.115-.182 1.626-.659 6.055-.686 6.949-.027.954.114 2.567 1.331 2.688.45.047.799-.047 1.095-.296.652-.544.88-1.72.914-2.123l.733-6.196a1.007 1.007 0 112.002.242l-.726 6.155c-.013.188-.208 2.278-1.619 3.468-.612.51-1.364.773-2.198.773z"
                fill="#3A3A3A"
            />
        </svg>
    )
}

export default IllustrationEvidenceMatch
