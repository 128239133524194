import styled, { css } from "styled-components";

const Text = styled.span`
    ${({ font }) => font && css`
        font-family: ${({ font }) => `${font}, sans-serif`};
    `}

    ${({ width }) => width && css`
        width: ${width};
    `}

    ${({ maxWidth }) => maxWidth && css`
        max-width: ${maxWidth};
    `}

    font-size: ${({ size }) => size ? size : 14}px;
    line-height: ${({ height }) => height ? height : 24}px;
    text-align: ${({ align }) => align ? align : "left"};
    color: ${({ color }) => color ? color : "black"};

    transition: all 0.5s linear;

    display: block;
`;

export default Text;