import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import styled from 'styled-components';
import { SvgIllustrationAcceptance, SvgIllustrationCuriosity, SvgIllustrationFreedom, SvgIllustrationGoal, SvgIllustrationHonor, SvgIllustrationMastery, SvgIllustrationOrder, SvgIllustrationPower, SvgIllustrationRelatedness, SvgIllustrationStatus, SvgRadar } from "../../assets/svgs";
import { Modal, Text } from "../../components";
import colors from '../../utils/colors';
import * as htmlToImage from 'html-to-image';
import LineTo from 'react-lineto';

const allowedPoints = {
    goal: [[461, 503], [443, 527], [424, 552], [405, 577], [387, 605], [369, 629], [349, 655], [331, 679], [313, 705], [294, 731]].reverse(),
    freedom: [[443, 478], [413, 487], [383, 496], [353, 506], [324, 516], [295, 527], [263, 536], [233, 545], [204, 554], [174, 565]].reverse(),
    acceptance: [[444, 448], [413, 438], [383, 429], [354, 418], [324, 409], [294, 399], [262, 389], [233, 380], [204, 369], [174, 361]].reverse(),
    curiosity: [[461, 423], [443, 398], [423, 374], [406, 348], [387, 321], [369, 297], [349, 271], [332, 246], [313, 220], [295, 194]].reverse(),
    relatedness: [[489, 413], [489, 384], [489, 353], [490, 321], [490, 287], [489, 258], [490, 225], [489, 195], [489, 162], [490, 131]].reverse(),
    power: [[519, 423], [537, 399], [555, 373], [574, 347], [592, 321], [610, 298], [631, 272], [648, 246], [666, 220], [685, 194]].reverse(),
    order: [[536, 449], [566, 439], [596, 429], [626, 419], [654, 409], [685, 399], [717, 390], [746, 380], [776, 370], [805, 361]].reverse(),
    mastery: [[534, 480], [566, 487], [596, 496], [625, 506], [654, 518], [684, 527], [716, 537], [746, 545], [775, 556], [805, 566]].reverse(),
    honor: [[519, 503], [538, 527], [555, 551], [574, 576], [592, 605], [610, 629], [629, 655], [648, 679], [666, 705], [684, 731]].reverse(),
    status: [[490, 512], [490, 542], [489, 573], [490, 604], [489, 638], [489, 668], [489, 701], [489, 730], [489, 763], [489, 794]].reverse()
}

const MovingMotivators = React.forwardRef(({ }, ref) => {
    const itemsRef = useRef([
        "GOAL",
        "RELATEDNESS",
        "CURIOSITY",
        "HONOR",
        "FREEDOM",
        "MASTERY",
        "ORDER",
        "POWER",
        "ACCEPTANCE",
        "STATUS"
    ]);
    const [items, setItems] = useState([
        { label: "Goal", Icon: SvgIllustrationGoal },
        { label: "Relatedness", Icon: SvgIllustrationRelatedness },
        { label: "Curiosity", Icon: SvgIllustrationCuriosity },
        { label: "Honor", Icon: SvgIllustrationHonor },
        { label: "Freedom", Icon: SvgIllustrationFreedom },
        { label: "Mastery", Icon: SvgIllustrationMastery },
        { label: "Order", Icon: SvgIllustrationOrder },
        { label: "Power", Icon: SvgIllustrationPower },
        { label: "Acceptance", Icon: SvgIllustrationAcceptance },
        { label: "Status", Icon: SvgIllustrationStatus },
    ]);

    const [points, setPoints] = useState([]);

    function onChange(_sourceId, sourceIndex, targetIndex, _targetId) {
        const nextState = swap(items, sourceIndex, targetIndex);
        itemsRef.current = nextState.map((item) => (item.label.toUpperCase()));
        setItems(nextState);
    }

    useImperativeHandle(ref, () => ({
        getValue: () => {
            const node = document.getElementById('radar-chart');

            return htmlToImage
                .toBlob(node)
                .then((blob) => {
                    return {
                        blob,
                        items: itemsRef.current
                    };
                });
        }
    }), []);

    useEffect(() => {
        const motivators = {};

        for (let i = 0; i < itemsRef.current.length; i++) {
            const item = itemsRef.current[i];
            motivators[item.toLowerCase()] = i + 1;
        }

        const pointsToDraw = [];

        for (let i = 0; i < Object.keys(motivators).length; i++) {
            const key = Object.keys(motivators)[i];

            if (motivators[key] === 0) {
                continue;
            }

            const coordinates = allowedPoints[key][motivators[key] - 1];
            pointsToDraw.push(<RadarChartPoint key={key} className={`radar-chart-point-${key}`} x={coordinates[0] - 8} y={coordinates[1] - 8} />);
        }

        setPoints(pointsToDraw);
    }, [items]);

    const [isHelpOpen, setIsHelpOpen] = useState(true);

    return (
        <>
            <Wrapper>
                <Text maxWidth="452px" align="center" color={colors.grey01} size={32} height={40} style={{ marginTop: 84, marginBottom: 88 }}>This is a card game that will help us find out what truly motivates you.</Text>
                <Center>
                    <Boxes>
                        <GridContextProvider onChange={onChange}>
                            <GridDropZone
                                id="items"
                                boxesPerRow={5}
                                rowHeight={214}
                                style={{ width: "100%", height: "400px" }}
                            >
                                {items.map((item, index) => (
                                    <GridItem key={item.label}>
                                        <Box>
                                            <Counter><Text color={"white"} size={12} height={16}>{index + 1}</Text></Counter>
                                            <item.Icon style={{ marginBottom: 20 }} />
                                            <Text color={colors.grey01} size={16} height={24}>{item.label}</Text>
                                        </Box>
                                    </GridItem>
                                ))}
                            </GridDropZone>
                        </GridContextProvider>
                    </Boxes>
                </Center>
                <RadarChart id="radar-chart" className="radar-chart">
                    <SvgRadar width="100%" height="100%" onClick={(event) => console.log(`${event.clientX}, ${event.clientY}`)} />
                    {points}
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-goal" to="radar-chart-point-freedom" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-freedom" to="radar-chart-point-acceptance" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-acceptance" to="radar-chart-point-curiosity" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-curiosity" to="radar-chart-point-relatedness" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-relatedness" to="radar-chart-point-power" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-power" to="radar-chart-point-order" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-order" to="radar-chart-point-mastery" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-mastery" to="radar-chart-point-honor" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-honor" to="radar-chart-point-status" borderColor={colors.green02} borderWidth={3} />
                    <LineTo delay="0" within="radar-chart" zIndex={0} from="radar-chart-point-status" to="radar-chart-point-goal" borderColor={colors.green02} borderWidth={3} />
                </RadarChart>
            </Wrapper>
            <Modal isOpen={isHelpOpen} onRequestClose={() => setIsHelpOpen(false)}>
                <div style={{ marginBottom: 12 }}><Text color={colors.purple02} size={32} height={44} align="center" width={"452px"}>Step 02 - Moving Motivators</Text></div>
                <div style={{ marginBottom: 8 }}>
                    <Text color={colors.grey01} size={24} height={32} align="center" width={"492px"}>
                        Order the 10 cards in descending order.
                        You should put in the first place what's most important to you and in the end the least relevant for you.
                    </Text>
                </div>
            </Modal>
        </>
    );
});

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    background-color: white;
`;

const RadarChart = styled.div`
    z-index: -1;
    height: 931px;
    width: 975px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
`;

const RadarChartPoint = styled.div`
    z-index: 1;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    position: absolute;
    left: ${({ x }) => x}px;
    top: ${({ y }) => y}px;
    background-color: ${colors.purple02};
`;

const Center = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 160px;
`;

const Boxes = styled.div`
    width: 1160px;
`;

const Box = styled.div`
    position: relative;
    height: 200px;
    width: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-flow: column;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    cursor: move;
    user-select: none;
`;

const Counter = styled.div`
    position: absolute;
    top: 12px;
    left: 12px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.purple02};
    border-radius: 12px;
`;

export default MovingMotivators;