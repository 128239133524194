import * as React from "react"

function IllustrationStatus(props) {
    return (
        <svg
            width={59}
            height={73}
            viewBox="0 0 59 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15.957 71.085H2.427A2.427 2.427 0 010 68.657v-24.84a2.427 2.427 0 012.427-2.427h13.53a2.427 2.427 0 012.427 2.426v24.84a2.427 2.427 0 01-2.427 2.428z"
                fill="#4CD6C5"
            />
            <path
                d="M33.674 71.085H21.14a2.926 2.926 0 01-2.926-2.927v-37.29a2.926 2.926 0 012.926-2.928h12.534a2.926 2.926 0 012.927 2.927v37.294a2.926 2.926 0 01-2.927 2.924z"
                fill="#4CD6C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.5 20.066a1.55 1.55 0 00-1.55 1.551v46.234a1.55 1.55 0 001.55 1.552h11.918c.854 0 1.55-.697 1.55-1.552V21.617a1.55 1.55 0 00-1.55-1.551H41.5zm-4.915 1.551c0-2.716 2.2-4.915 4.916-4.915h11.917c2.715 0 4.915 2.2 4.915 4.915v46.234a4.919 4.919 0 01-4.915 4.916H41.5a4.914 4.914 0 01-4.916-4.916V21.617z"
                fill="#412ECC"
            />
            <path
                d="M47.458 10.867a5.433 5.433 0 100-10.867 5.433 5.433 0 000 10.867z"
                fill="#E31C46"
            />
        </svg>
    )
}

export default IllustrationStatus
