import Diagram, { createSchema, useSchema } from "beautiful-react-diagrams";
import "beautiful-react-diagrams/styles.css";
import * as htmlToImage from 'html-to-image';
import React, { useImperativeHandle, useRef, useState } from "react";
import ContentEditable from 'react-contenteditable';
import styled from "styled-components";
import { SvgIconNode } from "../../assets/svgs";
import { Button, Modal, Text } from "../../components";
import colors from "../../utils/colors";

const CustomNodeWidth = 135;
const CustomNodeHeight = 45;
const CustomNodeCenterCoordinates = [(window.innerWidth / 2) - (CustomNodeWidth / 2), ((window.innerHeight / 2) - (CustomNodeHeight / 2)) - CustomNodeHeight];

const CustomNode = (props) => {
    const { id, data, outputs } = props;
    const { label = "", borderColor = colors.grey01, backgroundColor = "white", textColor = colors.grey01, editable, onClickDelete } = data;

    const editableRef = useRef(null);
    const htmlRef = useRef(label);

    return (
        <Node borderColor={borderColor} backgroundColor={backgroundColor} onClick={() => editable && editableRef.current.focus()}>
            {!!editable ?
                <ContentEditable
                    innerRef={editableRef}
                    html={htmlRef.current}
                    disabled={false}
                    onChange={(e) => htmlRef.current = e.target.value}
                    tagName="div"
                    style={{ textAlign: "center", color: textColor }}
                />
                :
                <Text color={textColor} size={14} height={24}>{label}</Text>
            }
            {React.cloneElement(outputs[0], {
                style: {
                    position: "absolute",
                    width: "6px",
                    height: "6px",
                    borderRadius: "3px",
                    background: "#444444",
                    bottom: "-3px"
                }
            })}
            {React.cloneElement(outputs[1], {
                style: {
                    position: "absolute",
                    width: "6px",
                    height: "6px",
                    borderRadius: "3px",
                    background: "#444444",
                    right: "-3px"
                }
            })}
            {React.cloneElement(outputs[2], {
                style: {
                    position: "absolute",
                    width: "6px",
                    height: "6px",
                    borderRadius: "3px",
                    background: "#444444",
                    top: "-3px"
                }
            })}
            {React.cloneElement(outputs[3], {
                style: {
                    position: "absolute",
                    width: "6px",
                    height: "6px",
                    borderRadius: "3px",
                    background: "#444444",
                    left: "-3px"
                }
            })}
        </Node>
    );
};

const PersonalMap = React.forwardRef(({ }, ref) => {

    const [isHelpOpen, setIsHelpOpen] = useState(true);

    const [schema, { onChange, addNode, removeNode }] = useSchema(createSchema({
        nodes: [
            {
                id: "node-central",
                data: {
                    borderColor: colors.purple02,
                    backgroundColor: "rgba(65, 46, 204, 0.1)",
                    textColor: colors.purple02,
                    label: "Me"
                },
                coordinates: [CustomNodeCenterCoordinates[0], CustomNodeCenterCoordinates[1]],
                render: CustomNode,
                outputs: [
                    { id: "node-central-port-bottom", alignment: "top" },
                    { id: "node-central-port-right", alignment: "right" },
                    { id: "node-central-port-top", alignment: "bottom" },
                    { id: "node-central-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-values",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Values"
                },
                coordinates: [CustomNodeCenterCoordinates[0] - 250, CustomNodeCenterCoordinates[1]],
                render: CustomNode,
                outputs: [
                    { id: "node-values-port-bottom", alignment: "top" },
                    { id: "node-values-port-right", alignment: "right" },
                    { id: "node-values-port-top", alignment: "bottom" },
                    { id: "node-values-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-work",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Work"
                },
                coordinates: [CustomNodeCenterCoordinates[0] + 250, CustomNodeCenterCoordinates[1]],
                render: CustomNode,
                outputs: [
                    { id: "node-work-port-bottom", alignment: "top" },
                    { id: "node-work-port-right", alignment: "right" },
                    { id: "node-work-port-top", alignment: "bottom" },
                    { id: "node-work-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-family",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Family"
                },
                coordinates: [CustomNodeCenterCoordinates[0], CustomNodeCenterCoordinates[1] + 150],
                render: CustomNode,
                outputs: [
                    { id: "node-family-port-bottom", alignment: "top" },
                    { id: "node-family-port-right", alignment: "right" },
                    { id: "node-family-port-top", alignment: "bottom" },
                    { id: "node-family-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-home",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Home"
                },
                coordinates: [CustomNodeCenterCoordinates[0], CustomNodeCenterCoordinates[1] - 150],
                render: CustomNode,
                outputs: [
                    { id: "node-home-port-bottom", alignment: "top" },
                    { id: "node-home-port-right", alignment: "right" },
                    { id: "node-home-port-top", alignment: "bottom" },
                    { id: "node-home-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-goals",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Goals"
                },
                coordinates: [CustomNodeCenterCoordinates[0] - 250, CustomNodeCenterCoordinates[1] - 150],
                render: CustomNode,
                outputs: [
                    { id: "node-goals-port-bottom", alignment: "top" },
                    { id: "node-goals-port-right", alignment: "right" },
                    { id: "node-goals-port-top", alignment: "bottom" },
                    { id: "node-goals-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-friends",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Friends"
                },
                coordinates: [CustomNodeCenterCoordinates[0] - 250, CustomNodeCenterCoordinates[1] + 150],
                render: CustomNode,
                outputs: [
                    { id: "node-friends-port-bottom", alignment: "top" },
                    { id: "node-friends-port-right", alignment: "right" },
                    { id: "node-friends-port-top", alignment: "bottom" },
                    { id: "node-friends-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-hobbies",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Hobbies"
                },
                coordinates: [CustomNodeCenterCoordinates[0] + 250, CustomNodeCenterCoordinates[1] - 150],
                render: CustomNode,
                outputs: [
                    { id: "node-hobbies-port-bottom", alignment: "top" },
                    { id: "node-hobbies-port-right", alignment: "right" },
                    { id: "node-hobbies-port-top", alignment: "bottom" },
                    { id: "node-hobbies-port-left", alignment: "left" }
                ],
            },
            {
                id: "node-education",
                data: {
                    borderColor: colors.green02,
                    backgroundColor: "rgba(76, 214, 197, 0.1);",
                    textColor: colors.green02,
                    editable: true,
                    label: "Education"
                },
                coordinates: [CustomNodeCenterCoordinates[0] + 250, CustomNodeCenterCoordinates[1] + 150],
                render: CustomNode,
                outputs: [
                    { id: "node-education-port-bottom", alignment: "top" },
                    { id: "node-education-port-right", alignment: "right" },
                    { id: "node-education-port-top", alignment: "bottom" },
                    { id: "node-education-port-left", alignment: "left" }
                ],
            },
        ],
        links: [
            { input: "node-central-port-left", output: "node-values-port-right" },
            { input: "node-central-port-right", output: "node-work-port-left" },
            { input: "node-central-port-left", output: "node-goals-port-right" },
            { input: "node-central-port-left", output: "node-friends-port-right" },
            { input: "node-central-port-bottom", output: "node-family-port-top" },
            { input: "node-central-port-right", output: "node-education-port-left" },
            { input: "node-central-port-right", output: "node-hobbies-port-left" },
            { input: "node-central-port-top", output: "node-home-port-bottom" }
        ]
    }));

    const deleteNodeFromSchema = (id) => {
        const nodeToRemove = schema.nodes.find(node => node.id === id);
        removeNode(nodeToRemove);
    };

    const addNewNode = () => {
        const id = Math.random();
        const nextNode = {
            id: `node-${id}`,
            content: `Node ${id}`,
            coordinates: [50, 100],
            render: CustomNode,
            data: {
                editable: true,
                onClickDelete: deleteNodeFromSchema
            },
            outputs: [
                { id: `node-${id}-port-1`, alignment: "top" },
                { id: `node-${id}-port-2`, alignment: "right" },
                { id: `node-${id}-port-top`, alignment: "bottom" },
                { id: `node-${id}-port-left`, alignment: "left" }
            ]
        };

        addNode(nextNode);
    }

    useImperativeHandle(ref, () => ({
        getValue: () => {
            const node = document.getElementById('personal-map');

            return htmlToImage
                .toBlob(node)
                .then((blob) => {
                    return {
                        blob,
                        schema
                    };
                });
        }
    }), []);

    return (
        <>
            <Wrapper>
                <Diagram id="personal-map" schema={schema} onChange={onChange} style={{ backgroundColor: "#ffffff", border: ".07rem solid #ffffff" }} />
                <Bottom>
                    <Button color={colors.green02} onClick={addNewNode}>
                        <Text
                            color={"white"}
                            size={16}
                            height={24}
                        >
                            Add node
                        </Text>
                        <SvgIconNode style={{ marginLeft: 8 }} />
                    </Button>
                </Bottom>
            </Wrapper>
            <Modal isOpen={isHelpOpen} onRequestClose={() => setIsHelpOpen(false)}>
                <div style={{ marginBottom: 12 }}><Text color={colors.purple02} size={32} height={44} align="center" width={"452px"}>Step 01 - Mind Map</Text></div>
                <div style={{ marginBottom: 8 }}>
                    <Text color={colors.grey01} size={24} height={32} align="center" width={"492px"}>
                        Build a mind map with your personal information.
                        Your name is the center so you just need to add branches to the main categories.
                    </Text>
                </div>
                <Text color={colors.grey01} size={14} height={24} align="center" width={"492px"}>
                    Don’t worry about including a lot of details, keywords and short phrases will be more than enough.
                    To add a branch you just need to go to the bottom left corner and click on "Add node", after the nod is added you just need to start writing inside of it. Keep adding "nodes" until you're sure you have a map that really characterizes you.
                </Text>
            </Modal>
        </>
    );
});

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

const Bottom = styled.div`
    position: absolute;
    bottom: 36px;
    left: 22px;
`;

const Node = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${CustomNodeWidth}px;
    min-height: ${CustomNodeHeight}px;
    left: 612px;
    top: 448px;
    background: ${({ backgroundColor }) => backgroundColor};
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 10px;
    padding: 6px;
`;

export default PersonalMap;