import * as React from "react"

function IllustrationHonor(props) {
    return (
        <svg
            width={42}
            height={84}
            viewBox="0 0 42 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20.74 83.375c11.454 0 20.74-9.286 20.74-20.74 0-11.454-9.286-20.74-20.74-20.74C9.286 41.895 0 51.181 0 62.635c0 11.454 9.286 20.74 20.74 20.74z"
                fill="#4CD6C5"
            />
            <path
                d="M20.74 41.895a4.663 4.663 0 100-9.327 4.663 4.663 0 000 9.327z"
                fill="#E31C46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.665 1.595C2.665.715 3.379 0 4.26 0h32.963c.881 0 1.596.714 1.596 1.595v22.297c0 .637-.38 1.213-.965 1.465l-16.483 7.1c-.403.173-.86.173-1.262 0l-16.48-7.1a1.595 1.595 0 01-.964-1.465V1.595zm3.19 1.595v19.652l14.885 6.413 14.888-6.413V3.19H5.855z"
                fill="#412ECC"
            />
        </svg>
    )
}

export default IllustrationHonor
