import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

@font-face {
  font-family: Aeonik-Regular;
  src: url(${process.env.PUBLIC_URL}/fonts/Aeonik-Regular.ttf);
}

@font-face {
  font-family: Aeonik-Bold;
  src: url(${process.env.PUBLIC_URL}/fonts/Aeonik-Bold.ttf);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Aeonik-Regular', sans-serif;
}

#root, html, body {
  height: 100%;
  font-size: 14px;
  color: #121F33;
}

body {
  font-family: 'Aeonik-Regular', sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.slide-pane__content {
  margin: 0;
  padding: 0;
}

.slide-pane_from_bottom {
    height: 100vh;
    margin-top: 0;
}

.rdw-editor-main {
  border: 1px solid #f1f1f1;
  padding: 0 12px;
  height: auto;
}

.infinite-scroll-component__outerdiv {
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 1;
}
`;