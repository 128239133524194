import * as React from "react"

function IllustrationAcceptance(props) {
    return (
        <svg
            width={76}
            height={76}
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.965 9.364c-15.796 0-28.6 12.805-28.6 28.601s12.804 28.602 28.6 28.602c15.796 0 28.602-12.805 28.602-28.601 0-15.797-12.805-28.602-28.601-28.602zM0 37.965C0 16.998 16.998 0 37.965 0c20.968 0 37.966 16.998 37.966 37.965 0 20.968-16.998 37.966-37.965 37.966C16.997 75.931 0 58.933 0 37.966z"
                fill="#4CD6C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.237 28.67a1.595 1.595 0 010 2.257L36.07 49.093a1.595 1.595 0 01-2.256 0l-9.083-9.083a1.595 1.595 0 112.256-2.256l7.955 7.956 17.039-17.04a1.595 1.595 0 012.256 0z"
                fill="#412ECC"
            />
            <path
                d="M24.409 42.095a4.663 4.663 0 100-9.326 4.663 4.663 0 000 9.326z"
                fill="#E31C46"
            />
        </svg>
    )
}

export default IllustrationAcceptance
