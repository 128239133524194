import * as React from "react"

function Logo(props) {
    return (
        <svg
            width={93}
            height={24}
            viewBox="0 0 93 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.316 7.277v4.031H6.538v12.226H2.345V11.308H0v-4.03H11.316zM18.483 11.308v12.226h-4.194V7.278h4.194v4.03zM30.9 6.82c1.776 0 3.248.598 4.409 1.79 1.161 1.19 1.738 2.84 1.738 4.94v9.985h-4.194v-9.467c0-1.088-.295-1.916-.88-2.486-.584-.577-1.368-.865-2.344-.865-1.088 0-1.953.333-2.604 1.006-.65.673-.976 1.679-.976 3.025v8.78h-4.194V7.277h4.194v1.82c1.02-1.517 2.64-2.279 4.852-2.279zM52.32.77h4.194v22.765H52.32v-1.916c-1.235 1.583-2.995 2.374-5.266 2.374-2.19 0-4.06-.828-5.614-2.485-1.553-1.657-2.322-3.69-2.322-6.102 0-2.41.776-4.437 2.322-6.101 1.553-1.657 3.417-2.485 5.614-2.485 2.278 0 4.03.79 5.266 2.374V.77zm-7.722 17.935c.858.858 1.938 1.287 3.24 1.287 1.302 0 2.374-.429 3.217-1.287.843-.858 1.272-1.96 1.272-3.299 0-1.346-.421-2.448-1.272-3.298-.843-.858-1.915-1.287-3.217-1.287s-2.382.429-3.24 1.287c-.858.858-1.286 1.96-1.286 3.298 0 1.346.428 2.449 1.286 3.299z"
                fill="#444"
            />
            <path
                d="M68.769 6.82c4.97 0 6.338 4.178 6.146 6.73l-.791 9.984h-4.127l.724-9.467c0-1.087-.296-1.915-.88-2.485-.584-.577-1.368-.865-2.344-.865-1.088 0-3.247.547-3.58 4.03l-.525 6.864a2.076 2.076 0 01-4.142-.303L60.722.762h4.26l-.614 8.017c.718-.85 2.19-1.96 4.4-1.96zM89.603 7.277h1.22c.866 0 1.547.74 1.48 1.598L91.171 23.54h-4.193l.148-1.915C86.106 23.142 84.345 24 82.133 24c-1.775 0-3.246-.6-4.408-1.79-1.16-1.19-1.738-2.84-1.738-4.94l.784-9.963 4.142-.022-.732 9.467c0 1.087.296 1.915.88 2.485.584.577 1.368.865 2.345.865 1.087 0 1.952-.333 2.603-1.006.65-.673 1.48-2.093 1.605-3.676l.518-6.76a1.468 1.468 0 011.471-1.383z"
                fill="#4CD6C5"
            />
            <path
                d="M6.523 7.278H2.39V5.776A5.784 5.784 0 018.165 0h4.741v4.134h-4.74c-.903 0-1.643.74-1.643 1.642v1.502zM16.382 4.201a2.1 2.1 0 100-4.201 2.1 2.1 0 000 4.201z"
                fill="#444"
            />
            <path
                d="M90.225 10.776a2.093 2.093 0 100-4.186 2.093 2.093 0 000 4.186z"
                fill="#E31C46"
            />
            <path
                d="M61.328 23.519a2.07 2.07 0 100-4.142 2.07 2.07 0 000 4.142z"
                fill="#412ECC"
            />
        </svg>
    )
}

export default Logo
