import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { App } from './containers';
import * as auth from './context/auth';
import GlobalStyle from './styles/global';
import client from './utils/client';

function Init({ children }) {
    return (
        <auth.Provider>
            <GlobalStyle />
            <ApolloProvider client={client}>
                {children}
            </ApolloProvider>
        </auth.Provider>
    );
}

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/:token">
                <Init children={<App />} />
            </Route>
        </Switch>
    </Router>
    , document.getElementById('root'));