import React from 'react';
import styled from 'styled-components';
import { SvgIllustrationEvidenceMatch } from '../../assets/svgs';
import { Button, Text } from '../../components';
import colors from '../../utils/colors';

function Entrance({ onClickStart }) {
    return (
        <Wrapper>
            <SvgIllustrationEvidenceMatch />
            <Text font="Aeonik-Bold" maxWidth="452px" align="center" color={colors.purple02} size={12} height={16} style={{ marginTop: 36, marginBottom: 12 }}>EVIDENCE MATCH</Text>
            <Text maxWidth="452px" align="center" color={colors.grey01} size={32} height={40} style={{ marginBottom: 12 }}>Hello, welcome to the Evidence Match</Text>
            <Text maxWidth="452px" align="center" color={colors.grey03} size={14} height={24} style={{ marginBottom: 32 }}>
                Ready to help us find the perfect job offer for you?
                Please fill in the next three brief steps. They will provide us with key elements about yourself that will help us find the perfect job "match" for you.
            </Text>
            <Button width={148} color={colors.purple02} onClick={onClickStart}>
                <Text
                    color={"white"}
                    size={16}
                    height={24}
                >
                    Let’s start
                </Text>
            </Button>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: white;
`;

export default Entrance;