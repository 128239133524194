import * as React from "react"

function IconCheck(props) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12 22l-.354.354a.5.5 0 00.707 0L12 22zm-5.646-6.354a.5.5 0 00-.708.707l.708-.707zm20-7.292a.5.5 0 10-.708-.708l.707.708zm-14 13.292l-6-6-.708.707 6 6 .707-.707zm0 .707l14-14-.708-.707-14 14 .707.707z"
                fill="#fff"
            />
        </svg>
    )
}

export default IconCheck
