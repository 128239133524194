import * as React from "react"

function IllustrationFreedom(props) {
    return (
        <svg
            width={95}
            height={88}
            viewBox="0 0 95 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M59.27 87.762H6.103A6.104 6.104 0 010 81.659V41.065a6.104 6.104 0 016.103-6.103H59.27a6.104 6.104 0 016.103 6.103v40.59a6.105 6.105 0 01-6.103 6.107z"
                fill="#4CD6C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.263 3.19c-10.592 0-19.181 8.59-19.181 19.182v12.593a1.595 1.595 0 11-3.19 0V22.372C49.892 10.018 59.909 0 72.263 0c12.355 0 22.372 10.018 22.372 22.372v12.593a1.595 1.595 0 01-3.19 0V22.372c0-10.592-8.59-19.182-19.182-19.182z"
                fill="#412ECC"
            />
            <path
                d="M34.673 68.162a6.8 6.8 0 100-13.6 6.8 6.8 0 000 13.6z"
                fill="#E31C46"
            />
        </svg>
    )
}

export default IllustrationFreedom
