import * as React from "react"

function IconClose(props) {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.25 4l-8.5 8.5M12.249 12.5L3.749 4"
                stroke="#fff"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default IconClose
